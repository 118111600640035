<template>
  <Icon tooltip="Defina o(s) filtro(s) para  visualização da agenda!">
    <b-button class="filter" id="filterDraw">
      <b-popover target="filterDraw" triggers="click focus blur" placement="top">
        <b-row>
          <b-col>
            <p class="filterTitle">Filtro</p>

            <b-form-group>
              <multi-select
                placeholder="Tipo de procedimento"
                v-model="tempProcedureType"
                :options="procedureOptions"
                class="multiSidebar"
              />
            </b-form-group>

            <b-form-group>
              <multi-select
                placeholder="Financeiro"
                v-model="tempFinanceiro"
                :options="faturaOptions"
                class="multiSidebar"
                :multiple="false"
              />
            </b-form-group>

            <b-form-group>
              <multi-select
                placeholder="Tipo de pendência"
                v-model="tempPendency"
                :options="pendenciesOptions"
                class="multiSidebar"
                :multiple="false"
              />
            </b-form-group>

            <b-form-group>
              <multi-select-priority
                placeholder="Prioridade"
                v-model="tempPriorityLevelValue"
                class="multiSidebar"
              />
            </b-form-group>

            <b-form-group>
              <multi-select
                placeholder="Situação"
                v-model="tempSituation"
                :options="situationOptions"
                class="multiSidebar"
                :multiple="false"
              />
            </b-form-group>

            <b-form-group>
              <multi-select
                placeholder="Convênio"
                v-model="tempHealthPlansValue"
                :options="healthPlansOptions"
                :searchable="true"
                class="multiSidebar"
              />
            </b-form-group>

            <b-form-group>
              <multi-select
                placeholder="Sala/setor"
                v-model="clinicRoomValue"
                :options="clinicRoomsOptions"
                class="multiSidebar"
              />
            </b-form-group>

            <b-form-group>
              <multi-select
                placeholder="Profissional"
                v-model="tempProfessionalValue"
                :options="doctorsOptions"
                :searchable="true"
                class="multiSidebar"
              />
            </b-form-group>

            <b-row>
              <b-col>
                <b-button variant="outline-primary" @click="cleanFilter">
                  Redefinir
                </b-button>
              </b-col>
              <b-col>
                <b-button variant="primary" @click="confirmFilter()">
                  Confirmar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-popover>
      <v-filter />
    </b-button>
  </Icon>
</template>

<script>
import Filter from "@/assets/icons/filter.svg";
import Icon from "@/components/General/Icon";

export default {
  components: {
    "v-filter": Filter,
    MultiSelectPriority: () =>
      import("@/modules/schedule/components/MultiSelectPriority"),
    // MultiSelectProfessionals: () => import('@/modules/schedule/components/MultiSelectProfessionals'),
    MultiSelect: () => import("@/modules/schedule/components/MultiSelect"),
    Icon,
  },
  mounted() {
    this.confirmFilter();

    this.getHealthPlans();
    this.getClinicRooms();
    this.getDoctors();
  },
  methods: {
    cleanFilter() {
      this.tempProcedureType = [];
      this.tempPriorityLevelValue = [];
      this.tempHealthPlansValue = [];
      this.tempProfessionalValue = [];
      this.clinicRoomValue = [];
      this.tempFinanceiro = null;
      this.tempSituation = null;
      this.tempPendency = null;

      this.filters = {
        procedureType: [],
        financeiroValue: null,
        pendencyValue: null,
        priorityLevelValue: [],
        situation: null,
        healthPlansValue: [],
        clinicRoomValue: [],
        doctorsValue: [],
      };
    },
    confirmFilter() {
      this.$emit("filters", {
        filters: this.filters,
      });

      this.$root.$emit("bv::hide::popover", "filterDraw");
    },
    async getHealthPlans() {
      const options = [];
      let next = true;
      let currentPage = 1;

      const plans = [];

      while (next) {
        await this.api
          .getClinicHealthPlans(this.clinic.id, currentPage)
          .then((response) => {
            for (let i = 0; i < response.data.data.length; i++) {
              if (
                plans.includes(
                  response.data.data[i].health_plan_fantasy_name.toLowerCase().trim()
                )
              ) {
                continue;
              }

              options.push({
                label: response.data.data[i].health_plan_fantasy_name,
                value: response.data.data[i].health_plan_fantasy_name.toLowerCase(),
              });

              plans.push(
                response.data.data[i].health_plan_fantasy_name.toLowerCase().trim()
              );
            }

            if (currentPage === response.data.last_page) {
              next = false;
            } else {
              currentPage++;
              next = true;
            }
          });
      }

      this.healthPlansOptions = options;
    },
    async getClinicRooms() {
      const options = [];
      let next = true;
      let currentPage = 1;

      while (next) {
        await this.api.getClinicRooms(this.clinic.id, currentPage).then((response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            options.push({
              label: response.data.data[i].name,
              value: response.data.data[i].id,
            });
          }

          if (currentPage === response.data.last_page) {
            next = false;
          } else {
            currentPage++;
            next = true;
          }
        });
      }

      this.clinicRoomsOptions = options;
    },
    async getDoctors() {
      const options = [];

      await this.api.getDoctors(this.clinic.id).then((response) => {
        for (let i = 0; i < response.data.doctors.length; i++) {
          options.push({
            label: response.data.doctors[i].name,
            value: response.data.doctors[i].id,
          });
        }
      });

      this.doctorsOptions = options;
    },
  },
  data() {
    return {
      false: false,
      clinic: JSON.parse(localStorage.getItem("clinic")),

      healthPlansOptions: [],
      clinicRoomsOptions: [],
      doctorsOptions: [],

      clinicRoomValue: [],
      tempProcedureType: [],
      tempPendency: null,
      tempPriorityLevelValue: [],
      tempHealthPlansValue: [],
      tempProfessionalValue: [],
      tempFinanceiro: null,
      tempSituation: null,

      filters: {
        procedureType: [],
        financeiroValue: null,
        pendencyValue: null,
        priorityLevelValue: [],
        situation: null,
        healthPlansValue: [],
        clinicRoomValue: [],
        doctorsValue: [],
        patient: null,
      },

      procedureOptions: [
        { label: "Centro cirúrgico", value: "SURGICAL" },
        { label: "Procedimento", value: "PROCEDURE" },
        { label: "Consulta", value: "APPOINTMENT" },
        { label: "Telemedicina", value: "TELEMEDICINE" },
        { label: "Exame", value: "EXAM" },
        { label: "Retorno", value: "RETURN" },
      ],
      faturaOptions: [
        { label: "Faturado", value: "paid_out" },
        { label: "Precisa faturar", value: "pending" },
      ],
      pendenciesOptions: [
        { label: "Pendência TISS", value: "tiss" },
        { label: "Pendência Particular", value: "particular" },
      ],
      situationOptions: [
        { label: "Cancelado", value: "CANCELLED" },
        { label: "Confirmado", value: "CONFIRMED" },
        { label: "Faltou", value: "MISSED" },
        { label: "Sem situação", value: "SCHEDULED" },
        { label: "Compareceu", value: "ATTEND" },
        { label: "Finalizado", value: "FINISHED" },
        { label: "Aguardando atendimento", value: "WAITING" },
        { label: "Em atendimento", value: "HAPPENING" },
        { label: "Em exames", value: "EXAMINATING" },
        { label: "Dilatando", value: "DILATING" },
        { label: "Pré consulta", value: "PRE_FINISHED" },
        { label: "Em pré-consulta", value: "IN_PRE_CONSULTATION" },
      ],
    };
  },
  watch: {
    clinicRoomValue: {
      handler: function (val) {
        this.filters.clinicRoomValue = val.map((item) => item.value);
      },
    },
    tempProcedureType: {
      handler: function (val) {
        this.filters.procedureType = val.map((item) => item.value);
      },
    },
    tempPendency: {
      handler: function (val) {
        this.filters.pendencyValue = val ? val.value : null;
      },
    },
    tempPriorityLevelValue: {
      handler: function (val) {
        this.filters.priorityLevelValue = val.map((item) => item.value);
      },
    },
    tempHealthPlansValue: {
      handler: function (val) {
        this.filters.healthPlansValue = val.map((item) => item.value);
      },
    },
    tempProfessionalValue: {
      handler: function (val) {
        this.filters.doctorsValue = val.map((item) => item.value);
      },
    },
    tempFinanceiro: {
      handler: function (val) {
        this.filters.financeiroValue = val ? val.value : null;
      },
    },
    tempSituation: {
      handler: function (val) {
        this.filters.situation = val ? val.value : null;
      },
    },
  },
};
</script>

<style>
#calendar-header-wrapper .multiselect .multiselect__tags {
  width: 100% !important;
}

.multiSidebar .multiselect__tags {
  border: 1px solid var(--neutral-300) !important;
  color: var(--type-active);
  height: 38px;
  padding: 15px 40px 15px 16px !important;
}

#calendar-header-wrapper .multiselect .multiselect__tags .multiselect__single {
  color: var(--type-active);
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
</style>

<style lang="scss" scoped>
.btn-primary,
.btn-outline-primary {
  width: 100%;
}

#filterDraw {
  border: none;
  background: transparent;
}

.filterTitle {
  font-size: 18px;
  color: var(--type-active);
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
</style>
